import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import {
  Drawer,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { hasAccess } from "../libs/rbac";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  list: {
    width: "15rem",
  },
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& $primary, & $icon": {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
});

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      left: false,
      isAuthenticated: false,
    };
  }

  componentWillMount() {
    this.setState({
      isAuthenticated: this.props.isAuthenticated,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated !== this.props.isAuthenticated) {
      this.setState({ isAuthenticated: nextProps.isAuthenticated });
    }
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  menus() {
    return [
      // { path: "/orders", name: "Orders" }, // Legacied as of: April 24, 2023
      { path: "/search_orders", name: "Search Orders" },
      {
        path: "/orders/create/?step=select-game",
        name: "Create New Order",
      },
      { path: "/seats", name: "Seats" },
      { path: "/seats-by-seller", name: "Seats by Seller" },
      { path: "/sell-requests", name: "Sell Requests" },
      { path: "/sell-request-approval", name: "Sell Request Approval" },
      { path: "/reset-user-password", name: "Reset User Password" },
      { path: "/sms", name: "Send SMS for Game" },
      { path: "/min-price-per-game", name: "Min Price Per Game" },
      { path: "/coupons", name: "Coupons" },
      { path: "/game-page", name: "Game Page" },
      { path: "/ticket-evolution", name: "Ticket Evolution" },
      { path: "/emergency-mode", name: "Emergency Mode" },
      { path: "/user-support", name: "User Support" },
      { path: "/blocked-users", name: "Blocked Users"},
      { path: "/seller", name: "Seller" },
      { path: "/activities", name: "Activities" },
      { path: "/seller-specific", name: "Seller Specific" },
      { path: "/setting", name: "Setting" },
      { path: "/unpaid-games", name: "Unpaid Games" },
    ];
  }

  renderMenus(currUser) {
    const { classes } = this.props;

    return this.menus().map((menu, index) => {
      if (currUser && hasAccess(menu.path, currUser.userGroup)) {
        return (
          <MenuItem
            key={index}
            className={classes.menuItem}
            component={Link}
            to={menu.path}
          >
            <ListItemIcon className={classes.icon}>
              <IconButton />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.primary }}
              inset
              primary={menu.name}
            />
          </MenuItem>
        );
      }
      return null;
      // Comment this to enable admin support restrictions
      //  else {
      //   return (
      //     <MenuItem
      //       key={index}
      //       className={classes.menuItem}
      //       component={Link}
      //       to={menu.path}
      //     >
      //       <ListItemIcon className={classes.icon}>
      //         <IconButton />
      //       </ListItemIcon>
      //       <ListItemText
      //         classes={{ primary: classes.primary }}
      //         inset
      //         primary={menu.name}
      //       />
      //     </MenuItem>
      //   );
      // }
    });
  }

  render() {
    const { classes, currUser } = this.props;

    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={this.toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="title"
            color="inherit"
            className={classes.grow}
            component={Link}
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            FansFirst
          </Typography>
          <Drawer
            open={this.state.left}
            onClose={this.toggleDrawer("left", false)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer("left", false)}
              onKeyDown={this.toggleDrawer("left", false)}
            >
              <div className={classes.list}>
                <MenuList>{this.renderMenus(currUser)}</MenuList>
                <Divider />
              </div>
            </div>
          </Drawer>
          {this.state.isAuthenticated ? (
            <Button
              color="inherit"
              style={{
                marginLeft: "auto",
              }}
              onClick={this.props.logout}
            >
              Logout
            </Button>
          ) : (
            <Button
              color="inherit"
              style={{
                marginLeft: "auto",
              }}
              component={Link}
              to="/login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

Nav.props = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Nav);
