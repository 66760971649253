import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, List } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import ThreeDSStatus from "../components/Setting/ThreeDSStatus";
import MaintenanceStatus from "../components/Setting/MaintenanceStatus";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: "1rem",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 3,
  },
});

class Setting extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ marginBottom: "2rem" }}
              variant="display2"
            >
              Settings
            </Typography>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Typography variant="title">
                3DS Status Setting
              </Typography>
              <Paper className={classes.container} elevation={1}>
                <List className={classes.root}>
                  <ThreeDSStatus currUser={this.props.currUser} />
                </List>
              </Paper>
              <Typography variant="title">
                Maintenance Checkout Setting
              </Typography>
              <Paper className={classes.container} elevation={1}>
                <List className={classes.root}>
                  <MaintenanceStatus currUser={this.props.currUser} />
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Grid>        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Setting);
