import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import CanucksSeatSections from '../constants/CanucksSeatSections';
import LionsSeatSections from '../constants/LionsSeatSections';
import LionsSpecialSeatSections from '../constants/LionsSpecialSeatSections';
import AbbotsfordCanucksSeatSections from '../constants/AbbotsfordCanucksSeatSections';

const teamSections = {
  'toronto-raptors': CanucksSeatSections.ZONES,
  'vancouver-whitecaps-fc': LionsSeatSections.ZONES,
  'bc-lions': LionsSpecialSeatSections.ZONES,
  'vancouver-canucks': AbbotsfordCanucksSeatSections.ZONES,
};

class SpecialSectionSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.section
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.section !== this.props.section) {
      this.setState({ value: nextProps.section });
    }
  }

  handleSelection = event => {
    this.setState({
      [event.target.name]: event.target.value
    });

    this.props.onSectionSelection(event.target.value);
  };

  render() {
    const { selectedTeam } = this.props;
    const sections = get(teamSections, selectedTeam, []);
    return (
      <FormControl>
        <InputLabel>Section</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: 'section',
            id: 'section'
          }}
          placeholder="Section"
        >
          {sections.map((section, index) => (
            <MenuItem value={section.label} key={index}>
              {section.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

SpecialSectionSelect.propsType = {
  selectedTeam: PropTypes.string.isRequired,
  onSectionSelection: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};

export default SpecialSectionSelect;
