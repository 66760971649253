import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { API } from "aws-amplify";
import { getTeamsBySlug } from "../../libs/shared/team-slug";
import Autocomplete from "./Autocomplete";
import TEAMS from "../../constants/Teams";
import { get, has, isEmpty } from "lodash";
import validator from "validator";
import CDialog from "../CDialog";

class AddModal extends Component {
  constructor(props) {
    super();
    this.state = {
      homeTeamSlug: "",
      homeTeam: "",
      city: "",
      shortName: "",
      longName: "",
      isTBD: false,
      isTestGame: false,
      dateTime: "",
      timezone: "",
      opponent: "",
      isDateDefined: false,
      isSubmitting: false,
      isShowOptions: false,
      isSubmitted: false,
    };
  }

  handleClose = () => {
    this.setState({ ...this.state, isShowOptions: false });
    this.props.onClose();
  };

  handleListItemClick = (value) => {
    this.props.onClose(value);
  };

  convertToUTC = (date, timezone) => {
    const offset =
      moment["tz"](moment(new Date(date)), timezone).utcOffset() * 60000;
    const offsetInHour = offset / 1000 / 60 / 60;
    const timeOfset = offsetInHour > 0 ? offsetInHour : offsetInHour * -1;

    const timzoneDateObject = new Date(`${date}:00.000-0${timeOfset}:00`);
    const utcStringDate = timzoneDateObject.toISOString();
    var parsedUTCDate = new Date(utcStringDate);
    return parsedUTCDate.valueOf();
  };

  handleChange = (event) => {
    const value =
      event.target.id === "isTestGame" ||
        event.target.id === "isTBD" ||
        event.target.id === "isDateDefined"
        ? event.target.checked
        : event.target.value;
    this.setState({ ...this.state, [event.target.id]: value });
  };

  generateName = () => {
    const { homeTeamSlug, opponent } = this.state;
    if (!isEmpty(homeTeamSlug) && !isEmpty(opponent)) {
      const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
      const shortName = `vs ${opponent}`;
      const longName = `${opponent} at ${team.fullName}`;
      this.setState({ shortName, longName });
    }
  }

  teamChange = (e) => {
    const homeTeamSlug = e.target.value;
    const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
    this.setState({
      ...this.state,
      homeTeamSlug,
      homeTeam: team.fullName,
      city: team.city,
      timezone: team.timezone
    }, this.generateName);
  }

  validateInput() {
    const {
      homeTeam,
      shortName,
      longName,
      opponent,
      isTBD,
      dateTime,
    } = this.state;
    return {
      ...(validator.isEmpty(homeTeam) && { homeTeamSlug: "Team is required" }),
      ...(validator.isEmpty(opponent) && { opponent: "Opponent is required" }),
      ...(validator.isEmpty(shortName) && { shortName: "Short name is required" }),
      ...(validator.isEmpty(longName) && { longName: "Long name is required" }),
      ...(!isTBD && validator.isEmpty(dateTime) && { dateTime: "Game date is required" }),
    };
  }

  handleSubmit = async () => {
    const {
      homeTeamSlug,
      homeTeam,
      city,
      timezone,
      shortName,
      longName,
      opponent,
      isTBD,
      isTestGame,
      dateTime,
      isDateDefined,
    } = this.state;
    const { currUser } = this.props;
    this.setState({ isSubmitted: true });
    if (!isEmpty(this.validateInput())) {
      return;
    }
    this.setState({ ...this.state, isSubmitting: true, isShowOptions: false });
    const utcMiliDate = this.convertToUTC(dateTime, timezone);

    try {
      const response = await API.post("v2", "marketplace/events/add", {
        headers: { email: currUser.email },
        body: {
          homeTeamSlug,
          homeTeam,
          city,
          timezone,
          shortName,
          longName,
          isTBD,
          isTestGame,
          dateTime: utcMiliDate,
          opponent: opponent,
          isDateDefined: isDateDefined,
        },
      });

      CDialog.success("Success", `The new game has been saved`);
      this.setState({
        homeTeamSlug: "",
        homeTeam: "",
        city: "",
        shortName: "",
        longName: "",
        isTBD: false,
        isTestGame: false,
        dateTime: "",
        timezone: "",
        opponent: "",
        isDateDefined: false,
        isShowOptions: false,
        isSubmitting: false,
        isSubmitted: false
      });
      this.props.onSuccess(response);
    } catch (e) {
      alert(e);
      this.setState({ ...this.state, isSubmitting: false });
    }
  };

  render() {
    const { open } = this.props;
    const {
      homeTeamSlug,
      shortName,
      longName,
      isTBD,
      isTestGame,
      dateTime,
      isSubmitting,
      opponent,
      isShowOptions,
      isSubmitted
    } = this.state;
    const validated = this.validateInput();

    return (
      <Dialog open={open}>
        <DialogTitle>New Game</DialogTitle>
        <DialogContent>
          <FormControl fullWidth error={isSubmitted && has(validated, 'homeTeamSlug')}>
            <InputLabel>Team</InputLabel>
            <Select
              inputProps={{
                name: 'homeTeamSlug',
                id: 'homeTeamSlug',
              }}
              value={homeTeamSlug}
              onChange={e => this.teamChange(e)}
            >
              {TEAMS.map(team => (
                <MenuItem key={team.homeTeamSlug} value={team.homeTeamSlug}>
                  {team.fullName}
                </MenuItem>
              ))}
            </Select>
            {isSubmitted && has(validated, 'homeTeamSlug') && <FormHelperText id="homeTeamSlug">{get(validated, 'homeTeamSlug')}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth style={{ marginTop: "1rem" }} error={isSubmitted && has(validated, 'opponent')}>
            <label style={{
              color: isSubmitted && has(validated, 'opponent') ? "#f44336" : "rgba(0, 0, 0, 0.54)", 
              fontSize: ".8rem", 
              marginBottom: "3px"
            }}>
              Opponent
            </label>
            {isShowOptions ? (
              <div>
                <Autocomplete
                  suggestions={getTeamsBySlug(homeTeamSlug)}
                  onSelect={(opponent) => {
                    this.setState({
                      ...this.state,
                      opponent,
                      isShowOptions: false,
                    }, this.generateName);
                  }}
                />
              </div>
            ) : (
              <div
                className="filter-input"
                onClick={() =>
                  this.setState({ ...this.state, isShowOptions: true })
                }
              >
                {opponent}
              </div>
            )}
            {isSubmitted && has(validated, 'opponent') && <FormHelperText id="opponent">{get(validated, 'opponent')}</FormHelperText>}
          </FormControl>
          <TextField
            id={"shortName"}
            name="shortName"
            value={shortName}
            onChange={this.handleChange}
            autoFocus
            margin="dense"
            label="Short Name"
            type="text"
            fullWidth
            error={isSubmitted && has(validated, 'shortName')}
            helperText={isSubmitted && get(validated, 'shortName')}
          />
          <TextField
            id={"longName"}
            name="longName"
            value={longName}
            onChange={this.handleChange}
            autoFocus
            margin="dense"
            label="Long Name"
            type="text"
            fullWidth
            error={isSubmitted && has(validated, 'longName')}
            helperText={isSubmitted && get(validated, 'longName')}
          />
          <FormControlLabel
            control={
              <Checkbox
                id={"isTBD"}
                checked={isTBD}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Is TBD"
          />
          <FormControlLabel
            control={
              <Checkbox
                id={"isTestGame"}
                checked={isTestGame}
                onChange={this.handleChange}
                color="primary"
              />
            }
            label="Is Test Game"
          />
          <TextField
            id={"dateTime"}
            value={dateTime}
            label="Game Date"
            onChange={this.handleChange}
            autoFocus
            margin="dense"
            type="datetime-local"
            fullWidth
            error={isSubmitted && has(validated, 'dateTime')}
            helperText={isSubmitted && get(validated, 'dateTime')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={isSubmitting ? () => { } : this.handleSubmit}
            color="primary"
          >
            {isSubmitting ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AddModal;
