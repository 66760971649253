const zones = [
  {
    label: "Press Level 1",
  },
  {
    label: "Press Level 2",
  },
  {
    label: "Press Level 3",
  },
  {
    label: "Press Level 4",
  },
  {
    label: "Press Level 5",
  },
  {
    label: "Press Level 6",
  },
  {
    label: "Press Level 7",
  },
  {
    label: "Press Level 8",
  },
  {
    label: "Press Level 9",
  },
  {
    label: "Press Level 10",
  },
  {
    label: "Press Level 11",
  },
  {
    label: "Press Level 12",
  },
  {
    label: "Press Level 13",
  },
  {
    label: "Press Level 14",
  },
  {
    label: "Press Level 15",
  },
  {
    label: "Press Level 16",
  },
  {
    label: "Press Level 17",
  },
  {
    label: "Press Level 18",
  },
  {
    label: "Second Level 201",
  },
  {
    label: "Second Level 202",
  },
  {
    label: "Second Level 203",
  },
  {
    label: "Second Level 204",
  },
  {
    label: "Second Level 205",
  },
  {
    label: "Second Level 206",
  },
  {
    label: "Second Level 207",
  },
  {
    label: "Second Level 208",
  },
  {
    label: "Second Level 209",
  },
  {
    label: "Second Level 210",
  },
  {
    label: "Second Level 211",
  },
  {
    label: "Second Level 212",
  },
  {
    label: "Second Level 213",
  },
  {
    label: "Second Level 214",
  },
  {
    label: "Second Level 215",
  },
  {
    label: "Second Level 216",
  },
  {
    label: "Second Level 217",
  },
  {
    label: "Second Level 218",
  },
  {
    label: "Second Level 219",
  },
  {
    label: "Second Level 220",
  },
  {
    label: "Second Level 221",
  },
  {
    label: "Second Level 222",
  },
  {
    label: "Second Level 223",
  },
  {
    label: "Second Level 224",
  },
  {
    label: "Second Level 225",
  },
  {
    label: "Second Level 226",
  },
  {
    label: "Second Level 227",
  },
  {
    label: "Second Level 228",
  },
  {
    label: "Lower Bowl 101",
  },
  {
    label: "Lower Bowl 102",
  },
  {
    label: "Lower Bowl 103",
  },
  {
    label: "Lower Bowl 104",
  },
  {
    label: "Lower Bowl 105",
  },
  {
    label: "Lower Bowl 106",
  },
  {
    label: "Lower Bowl 107",
  },
  {
    label: "Lower Bowl 108",
  },
  {
    label: "Lower Bowl 109",
  },
  {
    label: "Lower Bowl 110",
  },
  {
    label: "Lower Bowl 111",
  },
  {
    label: "Lower Bowl 112",
  },
  {
    label: "Lower Bowl 113",
  },
  {
    label: "Lower Bowl 114",
  },
  {
    label: "Lower Club 115",
  },
  {
    label: "Lower Club 116",
  },
  {
    label: "Lower Club 117",
  },
  {
    label: "Lower Club 118",
  },
  {
    label: "Lower Club 119",
  },
  {
    label: "Lower Club 120",
  },
  {
    label: "Lower Club 121",
  },
  {
    label: "Lower Club 122",
  },
  {
    label: "REST",
  },
  {
    label: "Platinum Club",
  },
  {
    label: "Press Level East General Admission",
  },
  {
    label: "Press Level West General Admission",
  },
];

export default Object.freeze({
  ZONES: zones,
});
