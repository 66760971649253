import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import _ from "lodash";
import querySearch from "stringquery";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Typography, Button, FormGroup } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  FormControlLabel,
  Radio,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { getSeatLabel, renderZoneName, renderRowName, testDataCheck } from "../libs/shared/helpers";
import { isAllowedAction } from "../libs/rbac";
import TEAMS from "../constants/Teams";

const styles = {
  root: {
    flexGrow: 1,
  },
  teamsList: {
    marginBottom: "1.5rem",
  },
  teamItemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  teamLogoWrapper: {
    marginBottom: "1.5rem",
    cursor: "pointer",
  },
  teamLogo: {
    width: 50,
  },
  teamName: {
    textAlign: "center",
    cursor: "pointer",
  },
  seatStatuses: {
    padding: "1rem",
  },
};

class Seats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      games: null,
      seats: null,
      isLoading: true,
      selectedGame: "",
      selectedTeam: null,
      status: "1", // 1 for active and 2 for sold
      confirmArchiveAllSeats: false
    };

    this.handleGameChange = this.handleGameChange.bind(this);
    this.onTeamSelection = this.onTeamSelection.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.archiveAllSeats = this.archiveAllSeats.bind(this);
    this.markInstantDelivery = this.markInstantDelivery.bind(this);
  }

  async componentWillMount() {
    let { game, team } = querySearch(this.props.location.search);

    let allGames = await this.games();
    let currentTime = new Date().getTime();
    const tbdGames = _.chain(allGames)
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return !doesNeedTestData && game.isTbd && !game.isArchived;
      })
      .sortBy("playoffSequence", "asc");
    const regularGames = _.chain(allGames)
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return (
          moment.tz(game.date, game.timezone).add(120, "minutes").valueOf() >=
          moment.tz(currentTime, game.timezone).valueOf() &&
          !doesNeedTestData &&
          !game.isTbd &&
          !game.isArchived
        );
      })
      .sortBy("date", "asc")
      .value();
    const games = [...regularGames, ...tbdGames];
    const nextUpcomingGame = games.find((game) => game.homeTeamSlug === team);

    const selectedGame = nextUpcomingGame ? nextUpcomingGame : games[0];

    team = team ? team : selectedGame.homeTeamSlug;
    game = game ? game : selectedGame.id;

    let filteredGames = null;
    let sortedSeats = null;

    if (game && team) {
      filteredGames = games.filter((game) => {
        if (game.homeTeamSlug === team) {
          return game;
        }

        return null;
      });

      const seats = await this.seats(game);
      sortedSeats = _.sortBy(seats, ["zoneNo", "zone", "row"]);
    }

    this.setState({
      isLoading: false,
      games,
      filteredGames,
      seats: sortedSeats,
      filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
      selectedTeam: team,
      selectedGame: game,
    });
  }

  seats = (game) => {
    return API.get(
      "v2",
      `marketplace/listings/by/eventId/${game}?include_seller_details=true&include_inactive=true`
    )
      .then((data) => {
        this.setState({ ...this.state, isLoading: false });
        return data;
      })
      .catch((e) => {
        alert("something wrong", JSON.stringify(e));
      });
  };

  games() {
    return API.get("v2", `marketplace/events/all?includePrevious=true`);
  }

  async handleGameChange(e) {
    const gameId = e.target.value;
    this.setState({ ...this.state, isLoading: true });
    const seats = await this.seats(gameId);
    const sortedSeats = _.sortBy(seats, ["zoneNo", "zone", "row"]);

    this.setState({
      selectedGame: gameId,
      seats: sortedSeats,
      status: "1",
      filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
    });
  }

  sortSeatsBy = (seats, sortBy) => {
    switch (sortBy) {
      case "section": {
        return _.sortBy(seats, ["zoneNo", "zone", "row"]);
      }

      case "price": {
        return _.sortBy(seats, ["price", "zoneNo", "zone", "row"]);
      }

      case "email": {
        return _.sortBy(seats, ["sellerEmail", "zoneNo", "zone", "row"]);
      }

      default: {
        return seats;
      }
    }
  }

  async archiveSeat(id, game) {
    try {
      const { seats, status, sortBy } = this.state;
      const { currUser } = this.props;

      await API.post(
        "v2",
        `marketplace/listings/archive?eventId=${game}&listingId=${id}&asAdmin=true`,
        { headers: { email: currUser.email } }
      );

      let currSeats = seats.map((seat) => {
        if (seat.seatId === id) {
          seat.isArchived = true;
        }

        return seat;
      });

      if (sortBy) {
        currSeats = this.sortSeatsBy(currSeats, sortBy);
      }

      this.setState({
        seats: currSeats,
        filteredSeats: this.getFilteredSeats(currSeats, status),
      });
    } catch (e) {
      alert(e);
    }
  }

  async restoreSeat(id, game) {
    try {
      const { seats, status, sortBy } = this.state;
      const { currUser } = this.props;

      await API.post(
        "v2",
        `marketplace/listings/restore?eventId=${game}&listingId=${id}`,
        { headers: { email: currUser.email } }
      );

      let currSeats = seats.map((seat) => {
        if (seat.seatId === id) {
          seat.isArchived = false;
        }

        return seat;
      });

      if (sortBy) {
        currSeats = this.sortSeatsBy(currSeats, sortBy);
      }

      this.setState({
        seats: currSeats,
        filteredSeats: this.getFilteredSeats(currSeats, status),
      });
    } catch (e) {
      alert(e);
    }
  }

  renderGames() {
    const { filteredGames, selectedGame, isLoading } = this.state;

    if (isLoading) {
      return (
        <Typography variant="title" align="center">
          Loading...Please wait...
        </Typography>
      );
    }

    if (!filteredGames) {
      return;
    }

    const menuItems = filteredGames.map((game) => {
      const { id, date, timezone, isTbd } = game;

      const regularGameDate = `${moment
        .tz(date, timezone)
        .format("ddd MMM DD h:mm A")}`;
      const gameDate = isTbd ? "TBD" : regularGameDate;

      return (
        <MenuItem value={id} key={id} selected={id === selectedGame}>
          {game.shortName} - {gameDate}
        </MenuItem>
      );
    });

    return (
      <Select
        value={selectedGame}
        autoWidth
        onChange={this.handleGameChange}
        name="selectedGame"
      >
        <MenuItem value=" " disabled>
          Select Game
        </MenuItem>
        {menuItems}
      </Select>
    );
  }

  onStatusChange(status) {
    const { seats } = this.state;

    this.setState({
      status,
      filteredSeats: this.getFilteredSeats(seats, status),
    });
  }
  async markInstantDelivery(e, seat) {
    const { currUser } = this.props;
    // update the seat in this.state.seats with the new isInstantDelivery
    const { seats } = this.state;
    const isChecked = e.target.checked;
    const selectedSeatId = seat.seatId;

    await API.post(
      "v2",
      `marketplace/listings/update?adminName=${currUser.name}&eventId=${seat.game}&listingId=${seat.seatId}&isInstantDelivery=${e.target.checked}`,
      { headers: { email: currUser.email } }
    );
    const newSeats = seats.map((originalSeat) => {
      if (selectedSeatId === originalSeat.seatId) {
        const lastIndexLogs = originalSeat.changeLogs.length - 1;
        originalSeat.isInstantDelivery = isChecked;
        originalSeat.changeLogs[lastIndexLogs].date = new Date().valueOf();
      }
      return originalSeat;
    });
    this.setState({ seats: newSeats });
  }

  renderStatuses() {
    const { status, selectedGame } = this.state;
    const { classes, currUser } = this.props;
    const { userGroup } = currUser;

    return (
      <>
        {isAllowedAction(userGroup) && (
          <FormGroup row className={classes.seatStatuses}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Active Seats"
              checked={status === "1"}
              onChange={() => this.onStatusChange("1")}
              disabled={!selectedGame}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Sold Seats"
              checked={status === "2"}
              onChange={() => this.onStatusChange("2")}
              disabled={!selectedGame}
            />
            <FormControlLabel
              value="3"
              control={<Radio />}
              label="Archived Seats"
              checked={status === "3"}
              onChange={() => this.onStatusChange("3")}
              disabled={!selectedGame}
            />
          </FormGroup>
        )}
      </>
    );
  }

  async handlePriceEdit(e, seat) {
    const { seats } = this.state;
    const { currUser } = this.props;

    const newPrice = parseFloat(e.currentTarget.value);
    if (newPrice !== seat.price && newPrice !== 0) {
      API.post(
        "v2",
        `marketplace/listings/update?adminName=${currUser.name}&eventId=${seat.game}&listingId=${seat.seatId}&price=${newPrice}`,
        { headers: { email: currUser.email } }
      );

      const currSeats = seats.map((s) => {
        if (s.seatId === seat.seatId) {
          const lastIndexLogs = s.changeLogs.length - 1;
          s.price = newPrice;
          s.changeLogs[lastIndexLogs].date = new Date().valueOf();
        }

        return s;
      });

      this.setState({
        seats: currSeats,
      });
    } else if (newPrice === 0) {
      alert("Please enter valid price");
      e.target.value = seat.price;
    }
  }

  sortSeats(by) {
    const { filteredSeats } = this.state;
    let sortedSeats;
    this.setState({
      sortBy: by,
    });

    switch (by) {
      case "section": {
        sortedSeats = _.sortBy(filteredSeats, ["zoneNo", "zone", "row"]);
        break;
      }

      case "price": {
        sortedSeats = _.sortBy(filteredSeats, [
          "price",
          "zoneNo",
          "zone",
          "row",
        ]);
        break;
      }

      case "email": {
        sortedSeats = _.sortBy(filteredSeats, [
          "sellerEmail",
          "zoneNo",
          "zone",
          "row",
        ]);
        break;
      }

      default: {
        sortedSeats = filteredSeats;
      }
    }

    this.setState({
      filteredSeats: sortedSeats,
    });
  }

  renderSeats() {
    const { currUser } = this.props;
    const { userGroup } = currUser;
    const { filteredSeats, isLoading, selectedTeam } = this.state;

    if (isLoading) {
      return null;
    }

    return (
      filteredSeats && (
        <React.Fragment>
          {this.renderStatuses()}
          <Paper
            style={{
              marginTop: "2rem",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => this.sortSeats("section")}
                  >
                    Section
                  </TableCell>
                  <TableCell> Row No </TableCell>
                  <TableCell
                    style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => this.sortSeats("price")}
                  >
                    Seat Price
                  </TableCell>
                  <TableCell> Seat Numbers </TableCell>
                  <TableCell> No.of Seats Available </TableCell>
                  <TableCell> Seats Sold </TableCell>
                  <TableCell> Insta-Delivery </TableCell>
                  <TableCell
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => this.sortSeats("email")}
                  >
                    Seller Email
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSeats.map((seat, index) => {
                  const lastIndexLogs = seat.changeLogs.length - 1;
                  const latestLogDate = seat.changeLogs[lastIndexLogs].date;
                  return (
                    <TableRow key={seat.seatId}>
                      <TableCell>
                        {renderZoneName(seat.zone, seat.zoneNo, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {renderRowName(seat.zone, seat.row, seat.gameDetails.homeTeamSlug, seat.gameDetails.isSpecial)}{" "}
                        <Typography
                          style={{ fontWeight: "bold", display: "inline" }}
                        >
                          {seat.isAisleSeat && parseInt(seat.seatsSold) === 0
                            ? "A"
                            : null}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {isAllowedAction(userGroup) ? (
                          <TextField
                            defaultValue={seat.price}
                            style={{ width: "45px" }}
                            type="number"
                            min="1"
                            max="999"
                            inputProps={{
                              tabIndex: ++index,
                            }}
                            onBlur={(e) => this.handlePriceEdit(e, seat)}
                          />
                        ) : (
                          `${seat.price}`
                        )}
                      </TableCell>
                      <TableCell>{getSeatLabel(seat)}</TableCell>
                      <TableCell>
                        {seat.noOfSeats - seat.seatsSold}/ {seat.noOfSeats}
                      </TableCell>
                      <TableCell> {seat.seatsSold} </TableCell>
                      <TableCell>
                        <FormControlLabel
                          style={{ height: "40px", marginTop: "10px" }}
                          control={
                            <Checkbox
                              onChange={(e) =>
                                this.markInstantDelivery(e, seat)
                              }
                              defaultChecked={seat.isInstantDelivery}
                              style={{ color: "rgb(33, 150, 243)" }}
                              height="40px"
                            ></Checkbox>
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {seat.sellerEmail ? seat.sellerEmail : "-"}
                      </TableCell>
                      <TableCell>
                        {isAllowedAction(userGroup) && (
                          <Button
                            href={`/seats/${seat.seatId}?game=${seat.game}`}
                          >
                            View Details
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        {isAllowedAction(userGroup) && (
                          <Button
                            component={Link}
                            to={`/seats/${seat.seatId}/update?game=${seat.game}&team=${selectedTeam}`}
                          >
                            Update ({moment(latestLogDate).format("M/D")})
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        {isAllowedAction(userGroup) && (
                          seat.isArchived ? (
                            <Button
                              onClick={() => this.restoreSeat(seat.seatId, seat.game)}
                            >
                              Restore
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                this.archiveSeat(seat.seatId, seat.game)
                              }
                              disabled={seat.isSold}
                            >
                              Archive
                          </Button>
                          )                          
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </React.Fragment>
      )
    );
  }

  getFilteredSeats(seats, status) {
    return _.filter(seats, (seat) => {
      if (status === "1" && !seat.isSold && !seat.isArchived) {
        return seat;
      } else if (status === "2" && seat.isSold) {
        return seat;
      } else if (status === "3" && seat.isArchived) {
        return seat;
      }
    });
  }

  async onTeamSelection(slug) {
    this.setState({
      selectedTeam: slug,
      isLoading: true,
    });

    console.log(slug);

    const { games } = this.state;

    if (!games) {
      return null;
    }

    const filtered = games.filter((game) => {
      if (game.homeTeamSlug === slug) {
        return game;
      }

      return null;
    });

    const nextUpcomingGame = games.find((game) => game.homeTeamSlug === slug);

    const selectedGameId = nextUpcomingGame ? nextUpcomingGame.id : games[0].id;

    const seats = await this.seats(selectedGameId);
    const sortedSeats = _.sortBy(seats, ["zoneNo", "zone", "row"]);

    this.setState({
      filteredGames: filtered,
      isLoading: false,
      selectedGame: selectedGameId,
      seats: sortedSeats,
      status: "1",
      filteredSeats: this.getFilteredSeats(sortedSeats, "1"),
    });
  }

  renderLogos() {
    const { selectedTeam, games } = this.state;
    const { classes } = this.props;

    if (!games) {
      return <Typography> Loading please wait... </Typography>;
    }

    return (
      <React.Fragment>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{
            fontWeight: "bold",
            marginBottom: "1rem",
          }}
        >
          Select Team
        </Typography>
        <Grid container spacing={32} className={classes.teamsList}>
          {TEAMS.map(team => (
            <Grid
              key={team.homeTeamSlug}
              item
              xs={6}
              md={1}
              className={classes.teamItemWrapper}
              onClick={() => this.onTeamSelection(team.homeTeamSlug)}
              style={
                selectedTeam && selectedTeam !== team.homeTeamSlug
                  ? {
                    opacity: 0.5,
                    transition: ".25s all ease-in-out",
                  }
                  : {
                    opacity: 1,
                    transition: ".25s all ease-in-out",
                  }
              }
            >
              <div className={classes.teamLogoWrapper}>
                <img
                  className={classes.teamLogo}
                  src={team.logo}
                  alt={team.fullName}
                />
              </div>
              <Typography variant="body2" className={classes.teamName}>
                {team.fullName}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  }

  async archiveAllSeats() {
    this.setState({
      isArchiving: true,
    });

    try {
      await API.post(
        "v2",
        `marketplace/listings/archive-all-event-listings?eventId=${this.state.selectedGame}`
      );

      const currSeats = this.state.seats.map((seat) => {
        seat.isArchived = true;
        return seat;
      });

      this.setState({
        isArchiving: false,
        seats: currSeats,
        filteredSeats: this.getFilteredSeats(currSeats, this.state.status),
        confirmArchiveAllSeats: false,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        isArchiving: false,
        confirmArchiveAllSeats: false,
      });

      alert("Sorry something went wrong...");
    }
  }

  renderArchiveAllDialog() {
    const { confirmArchiveAllSeats, isArchiving } = this.state;

    return (
      <Dialog
        open={confirmArchiveAllSeats}
        onClose={() => this.setState({ confirmArchiveAllSeats: false })}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Are you sure you want to archive all seats?
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Press Archive All button to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isArchiving}
            onClick={() => this.setState({ confirmArchiveAllSeats: false })}
          >
            Cancel
          </Button>
          <Button
            disabled={isArchiving}
            variant="contained"
            onClick={this.archiveAllSeats}
            color="primary"
          >
            Archive All
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes, currUser } = this.props;
    const { userGroup } = currUser;
    const { selectedTeam } = this.state;

    return (
      <div className={classes.root} id="Seats">
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="display2"> Seats </Typography>
          </Grid>
          {isAllowedAction(userGroup) && (
            <Grid item xs={8}>
              <Button
                component={Link}
                to={`/new-seats`}
                variant="contained"
                size="large"
                color="secondary"
              >
                Add New Seats
              </Button>
              {/* <Button NOTE: temp hide archive all seats
              variant="text"
              size="large"
              color="secondary"
              onClick={() => this.setState({ confirmArchiveAllSeats: true })}
            >
              Archive All Seats
            </Button> */}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{
              marginTop: "2rem",
            }}
          >
            {this.renderLogos()} {selectedTeam && this.renderGames()}
            {this.renderSeats()}
          </Grid>
        </Grid>
        {this.renderArchiveAllDialog()}
      </div>
    );
  }
}

export default withStyles(styles)(Seats);
