import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  FormControl,
} from "@material-ui/core";
import { API } from "aws-amplify";
import EditModal from "../components/ui-kit/EditModal";
import { getLocalizedDateTime, testDataCheck } from "../libs/shared/helpers";
import querySearch from "stringquery";
import TEAMS from "../constants/Teams";
import AddModal from "../components/ui-kit/AddModal";
import { sortBy } from "lodash";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class GamePage extends Component {
  constructor(props) {
    super();
  }

  state = {
    team: "calgary-flames",
    teamSelectOpen: false,
    editOpen: false,
    addOpen: false,
    archiveOpen: false,
    selectedGame: {
      shortName: "",
      longName: "",
      isTBD: false,
      isTestGame: false,
      dateTime: new Date("2022-01-18T21:11:54"),
    },
    gameData: {
      games: [],
      isLoadingGames: false,
    },
  };

  handleClickOpen(event, data) {
    this.setState({ teamSelectOpen: true });
    this.setState({
      ...this.state,
      editOpen: true,
      selectedGame: {
        id: data.id,
        shortName: data.shortName,
        longName: data.longName,
        isTBD: data.isTbd,
        isTestGame: data.testGame,
        dateTime: data.date,
        timezone: data.timezone,
        isArchived: event.currentTarget.value === "archive" ? true : undefined,
        opponent: data.opponent,
        homeTeamSlug: data.homeTeamSlug,
        isDateDefined: data.isDateDefined,
      },
    });
  }

  handleClose = () => {
    this.setState({ teamSelectOpen: false });
    this.setState({ editOpen: false });
    this.setState({ archiveOpen: false });
  };

  handleChange = (event) => {
    this.setState({
      gameData: { games: [], isLoadingGames: true },
      team: event.target.value
    });
    this.loadGames({ homeTeamSlug: event.target.value });
  };

  games = async ({ homeTeamSlug }) => {
    return API.get(
      "v2",
      `marketplace/events/by/homeTeamSlug/${homeTeamSlug}/?includeMinPrices=True`
    );
  };

  loadGames = async ({ homeTeamSlug }) => {
    const homeGames = await this.games({ homeTeamSlug });
    const activeGames = homeGames
      .filter((game) => {
        const doesNeedTestData = testDataCheck(game);
        return !game.isArchived && !doesNeedTestData
      })
      .sort((a, b) => a.playoffSequence - b.playoffSequence);

    this.setState({
      gameData: { games: activeGames, isLoadingGames: false },
    });
  };

  openAddDialog() {
    this.setState({ addOpen: true });
  }

  handleAddGameSuccess = (game) => {
    if (game.homeTeamSlug === this.state.team) {
      const newGameData = [...this.state.gameData.games, game];
      this.setState({
        gameData: {
          ...this.state.gameData,
          games: sortBy(newGameData, 'date'),

        },
        addOpen: false
      });
    } else {
      this.setState({ addOpen: false });
    }
  }

  renderGamesRow(game) {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Short Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Archive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {game.games.map((data) => {
              const localizedDate = getLocalizedDateTime(data.date, data.timezone, "hh:mm A");
              return (
                <TableRow key={data.id}>
                  <TableCell> {data.shortName}</TableCell>
                  <TableCell>
                    {" "}
                    {localizedDate.stringDate}{" "}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {localizedDate.stringTime}{" "}
                  </TableCell>
                  <TableCell>
                    <Button
                      value={"edit"}
                      variant="contained"
                      color="primary"
                      onClick={(event) => this.handleClickOpen(event, data)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      value={"archive"}
                      variant="contained"
                      color="secondary"
                      onClick={(event) => this.handleClickOpen(event, data)}
                    >
                      Archive
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colSpan={5}>
                {game.isLoadingGames && <Grid>Loading game data...</Grid>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  render() {
    const { classes, currUser } = this.props;
    const { gameData } = this.state;
    return (
      <div className={classes.root} id="GamePage">
        <Grid sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}></Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="display2">Games</Typography>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel>Team</InputLabel>
                <Select
                  id={"team"}
                  name="team"
                  value={this.state.team}
                  onChange={this.handleChange}
                >
                  {TEAMS.map(team => (
                    <MenuItem key={team.homeTeamSlug} value={team.homeTeamSlug}>
                      {team.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                value={"archive"}
                variant="contained"
                color="secondary"
                onClick={() => this.openAddDialog()}
              >
                Add Game
              </Button>
            </Grid>
          </Grid>
          {this.renderGamesRow(gameData || [])}
        </Grid>
        <EditModal
          selectedData={this.state.selectedGame}
          open={this.state.editOpen}
          onClose={() => {
            this.setState({ ...this.state, editOpen: false });
          }}
          currUser={currUser}
        />
        <AddModal
          open={this.state.addOpen}
          onClose={() => {
            this.setState({ ...this.state, addOpen: false });
          }}
          onSuccess={this.handleAddGameSuccess}
          currUser={currUser}
        />
      </div>
    );
  }

  componentDidMount() {
    const { gameData } = this.state;
    const { selectedTeam } = querySearch(this.props.location.search);
    this.setState({
      team: selectedTeam || "calgary-flames",
      gameData: { ...gameData, isLoadingGames: true },
    });
    this.loadGames({ homeTeamSlug: selectedTeam || "calgary-flames" });
  }
}

export default withStyles(styles)(GamePage);
